export const ROUTE_PATH_KEY = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGET: 'forget',
  INVITE: 'invite',
  THIRD_BIND: 'third-bind',
  THIRD_REGISTER: 'third-register',
};
export const ACCOUNT_ROUTE_PATH = {
  SECURITY_SETTING: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
    },
  },
  RESET_EMAIL: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
      option: 'reset-email',
    },
  },
  RESET_PHONE: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
      option: 'reset-phone',
    },
  },
  RESET_FUND_PASSWORD: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
      option: 'reset-funds-password',
    },
  },
  RESET_LOGIN_PASSWORD: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
      option: 'reset-login-password',
    },
  },
  RESET_TYPE: {
    PATHNAME: '/account/dashboard',
    QUERY: {
      type: 'security-setting',
      option: 'reset-type',
    },
  },
};
export const BAR_4IMG_MAP: any = {
  1: '/static/images/register/bar-level-1.svg',
  2: '/static/images/register/bar-level-2.svg',
  3: '/static/images/register/bar-level-3.svg',
  4: '/static/images/register/bar-level-4-active.svg',
};
export const BAR_5IMG_MAP: any = {
  1: '/static/images/register/bar-level-1.svg',
  2: '/static/images/register/bar-level-2.svg',
  3: '/static/images/register/bar-level-3.svg',
  4: '/static/images/register/bar-level-4.svg',
  5: '/static/images/register/bar-level-5-active.svg',
};
export const VOLUME_HEIGHT: Record<number, number> = {
  1: 60,
  2: 91,
  3: 153,
  4: 208,
  5: 304,
};
export const MOBILE_BAR_4IMG_MAP: any = {
  1: '/static/images/register/mobile/bar-level-1.svg',
  2: '/static/images/register/mobile/bar-level-2.svg',
  3: '/static/images/register/mobile/bar-level-3.svg',
  4: '/static/images/register/mobile/bar-level-4-active.svg',
};
export const MOBILE_BAR_5IMG_MAP: any = {
  1: '/static/images/register/mobile/bar-level-1.svg',
  2: '/static/images/register/mobile/bar-level-2.svg',
  3: '/static/images/register/mobile/bar-level-3.svg',
  4: '/static/images/register/mobile/bar-level-4.svg',
  5: '/static/images/register/mobile/bar-level-5-active.svg',
};
export const MOBILE_VOLUME_HEIGHT: Record<number, number> = {
  1: 40,
  2: 118,
  3: 168,
  4: 262,
  5: 360,
};
