import { LOCAL_KEY, localStorageApi } from '@/core/store';
import { useEffect, useState } from 'react';

export const useLocalCountdown = (type: LOCAL_KEY, time = 90, loop = false) => {
  const [countdown, setCountdown] = useState(time);
  const [isActive, setIsActive] = useState(false);

  const startCountdown = (tick: number = time) => {
    setIsActive(true);
    setCountdown(tick);
  };

  const resetCountdown = (tick: number = time) => {
    setCountdown(tick);
    setIsActive(false);
    localStorageApi.removeItem(type);
  };

  useEffect(() => {
    const localTime = Number(localStorageApi.getItem(type));
    if (localTime && localTime > 0 && localTime < time) {
      setCountdown(localTime);
      setIsActive(true);
    } else {
      localStorageApi.setItem(type, time);
    }
  }, [type]);

  useEffect(() => {
    let interval: any;
    if (isActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((countdown) => {
          const newCountdown = countdown - 1;
          localStorageApi.setItem(type, newCountdown);
          return newCountdown;
        });
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(interval);
      if (loop) {
        startCountdown(); // Restart countdown if loop is true
      } else {
        setIsActive(false);
        localStorageApi.removeItem(type);
      }
    }

    return () => clearInterval(interval);
  }, [isActive, countdown, type, time, loop]);

  return { countdown, isActive, startCountdown, resetCountdown };
};
